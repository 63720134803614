const pricing = [ 
    {   
        id: 1, 
        title: 'basic',
        price: 20,
        list: [
            {id:1, name: 'Opertion'},
            {id:2, name: 'Maintenance'},
            {id:3, name: 'Installation'},
            {id:4, name: 'Quality Components'},
            {id:5, name: 'Battery Materials'},
        ],
    },
    {  
        id: 2, 
        title: 'advanced',
        price: 50,
        list: [
            {id:1, name: 'Opertion'},
            {id:2, name: 'Maintenance'},
            {id:3, name: 'Installation'},
            {id:4, name: 'Quality Components'},
            {id:5, name: 'Battery Materials'},
        ],
    },
    {  
        id: 3, 
        title: 'premium',
        price: 100,
        list: [
            {id:1, name: 'Opertion'},
            {id:2, name: 'Maintenance'},
            {id:3, name: 'Installation'},
            {id:4, name: 'Quality Components'},
            {id:5, name: 'Battery Materials'},
        ],
    },
];

export default pricing;