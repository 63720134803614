import React from 'react';

import { Header, Footer, ScrollTop } from './components';
import { Home, About, Counter, Services, Projects, Pricing, Team, Testimonials, Blogs, Contact } from './sections';



const App = () => {
  return (
    <div className='App'>
      <Header />
      <Home />
      <About />
      <Counter />
      <Services />
      <Projects />
      <Pricing />
      <Team />
      <Testimonials />
      <Blogs />
      <Contact />
      <Footer />
      <ScrollTop />
    </div>

  )
}

export default App
