import { images } from '../constants';

const blogs = [ 
    {   
        id: 1, 
        image: images.blog1,
        heading: 'How to Maintain Your Solar Panels',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '2nd jan, 2022',
    },
    {
        id: 2,
        image: images.blog2,
        heading: 'Solar Tech Gadgets for Your Home',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '5th feb, 2022',
    },
    {
        id: 3,
        image: images.blog3,
        heading: 'Solar Tech Advancements of 2022',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '4th april, 2022',
    },
];

export default blogs;