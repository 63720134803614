import React from 'react';
import './Services.css';

import { Heading } from '../../components';
import { serviceContent } from '../../constants';

 

const Services = () => {
  return (
    <section className='services' id='services'>

      <Heading title={'our services'} span={'what we offer'} />

      <div className='box-container'>  
        {
          serviceContent.map((service) => {
            return (
              <div className='service-item' key={service.id}>
                <div className='icon'>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.content}</p>
              </div>
            )
            })
        }
      </div>
 
    </section>
  )
}

export default Services