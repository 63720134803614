import logo from '../assets/Logo/logo.png';

import home1 from '../assets/Home/Home-1.png';
import home2 from '../assets/Home/Home-2.png';
import home3 from '../assets/Home/Home-3.png'; 

import about from '../assets/About/About-Video.mp4';

import counter from '../assets/Counter/Counter.png';

import project1 from '../assets/Projects/Project-1.png';
import project2 from '../assets/Projects/Project-2.png';
import project3 from '../assets/Projects/Project-3.png';
import project4 from '../assets/Projects/Project-4.png';
import project5 from '../assets/Projects/Project-5.png';
import project6 from '../assets/Projects/Project-6.png';

import team1 from '../assets/Team/Team-1.png';
import team2 from '../assets/Team/Team-2.png';
import team3 from '../assets/Team/Team-3.png';
import team4 from '../assets/Team/Team-4.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';

import blog1 from '../assets/Blogs/Blog-1.png';
import blog2 from '../assets/Blogs/Blog-2.png';
import blog3 from '../assets/Blogs/Blog-3.png';



const Images = {
    logo,
    
    home1,
    home2,
    home3,

    about,

    team1,
    team2,
    team3,
    team4,

    testimonial1,
    testimonial2,
    testimonial3,

    blog1,
    blog2,
    blog3,

    counter,

    project1,
    project2,
    project3,
    project4,
    project5,
    project6,
};

export default Images;