import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsMapFill, BsClockFill } from 'react-icons/bs';

  
const contacts = [
    {
        id: 1,
        icon: <AiTwotonePhone />,
        title: 'contact',
        content: ['+123-456-789', '+111-222-333'],
    },
    {
        id: 2,
        icon: <BsClockFill />,
        title: 'opening hours',
        content: ['Mon - Fri : 8am - 6pm', 'Sat - Sun : 10am - 4pm'],
    },
    {
        id: 3,
        icon: <FaEnvelope />,
        title: 'email',
        content: ['abc@gmail.com', 'xyz@gmail.com'],
    },
    {
        id: 4,
        icon: <BsMapFill />,
        title: 'address',
        content: ['karachi, pakistan'],
    },
 
];

export default contacts;