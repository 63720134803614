import { images } from '../constants';

const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'Renewable Energy for a Sustainable World',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'save the Earth from Pollution',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',   },
    { 
        id: 3,
        image: images.home3,
        title: 'Solar power, clean and safe energy',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',       
    },

];

export default home;