import { images } from '.'; 
 
const projectContent = [
    {
        id: 1,
        image: images.project1,
        title: 'Apartment Solar System',
        category: 'Solar panels',
        tag: 'solar',
        link: '#'
    },
    {
        id: 2,
        image: images.project2,
        title: 'Solar power supply',
        category: 'Solar panels',
        tag: 'solar',
        link: '#'
    },
    {
        id: 3,
        image: images.project3,
        title: 'Wind Farm',
        category: 'wind energy',
        tag: 'wind',
        link: '#',
    },
    {
        id: 4,
        image: images.project5,
        title: 'power station',
        category: 'wind energy',
        tag: 'wind',
        link: '#'
    },
    {
        id: 5,
        image: images.project4,
        title: 'Hydropower plant',
        category: 'water turbines',
        tag: 'water',
        link: '#'
    },
    {
        id: 6,
        image: images.project6,
        title: 'river tower',
        category: 'water turbines',
        tag: 'water',
        link: '#'
    },
];

export default projectContent;

