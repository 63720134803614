import React from 'react';
import './Footer.css';

import { images } from '../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaAngleDoubleRight } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill } from 'react-icons/bs';

 
  
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        <div className='footer-item'>
          <a href='#' className='logo'>
            <img src={images.logo} alt='header-logo' />
            <h2> SunPower </h2>  
          </a>
          <p>Solar energy helps to protect your environment and Protect the Earth from Pollution.
            So say no to air pollution and water pollution.</p>
          <div class="social">
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

        <div className='footer-item'>
          <h2>quick links</h2>
          <div className='info links'>
            {['home', 'about', 'services', 'projects', 'pricing', 'testimonials', 'contact'].map((item) => (
              <p key={`link-${item}`}>
                <FaAngleDoubleRight className='icon' />
                <a href={`#${item}`}>{item}</a>
              </p>
              ))
            } 
          </div>
        </div> 

        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;