import React from 'react'
import './Blogs.css';

import { Heading } from '../../components';
import { blogContent } from '../../constants';


 
const Blogs = () => {  
  return (
    <section className='blog' id='blog'> 

      <Heading title={'our blogs'} span={'latest blogs & news'} />
 
      <div className='box-container'>
        { 
          blogContent.map((blog) => { 
            return <div className='blog-item' key={blog.id}>
              <div className='image'>
                <img src={blog.image} alt='Blog' />
              </div>
              <div className='content'>
                <div className='date'>{blog.date}</div>
                <div className='category'>{blog.category}</div><br />
                <a className='main-heading' href={blog.link}>{blog.heading}</a>
                <p>{blog.content}</p>    
              </div>

            </div> 
          })
        }
      </div>

    </section>
  )
}

export default Blogs