
import { FaSolarPanel } from 'react-icons/fa';
import { BsTools } from 'react-icons/bs';
import { GiWindTurbine, GiWaterMill, GiGreenPower, GiSolarPower } from 'react-icons/gi';


const services = [
    {
        id: 1,
        icon: <FaSolarPanel />,
        title: 'solar panel installation',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 2,
        icon: <GiWindTurbine />,
        title: 'wind energy installation',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 3,
        icon: <GiWaterMill />,
        title: 'water turbines',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        
    },
    {
        id: 4,
        icon: <GiGreenPower />,
        title: 'hybrid energy',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 5,
        icon: <GiSolarPower />,
        title: 'quality components selection',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 6,
        icon: <BsTools />,
        title: 'Maintenance services',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },

];

export default services;