import React from 'react';
import './Pricing.css';

import { Button, Heading } from '../../components';
import { pricingContent } from '../../constants';


  
const Pricing = () => { 
  return (  
    <section className='pricing' id='pricing'>

      <Heading title={'our pricing'} span={'our pricing & plan'} />

      <div className='box-container'>
        { 
          pricingContent.map((item) => {
            const listItems = item.list;
            return(
              <div class="price-item" key={item.id}>
                <div className='intro'>
                  <h3>{item.title}</h3>
                  <div class="price"><span>$</span>{item.price}<span>/month</span></div>
                </div>
                <ul>
                  {
                    listItems.map((l) => {
                      return <li key={l.id}>{l.name}</li>
                    })
                  }
                </ul>
                <Button title={'choose plan'} />
              </div>
            )
          })
        }
      </div>

    </section>
  )
}

export default Pricing;