import React from 'react'; 
import './About.css';

import { Heading, Button } from '../../components';
import { images } from '../../constants';

import { TiTick } from 'react-icons/ti';
import ReactPlayer from 'react-player';



const About = () => {  
  return (
    <section className='about' id='about'>

      <Heading title={'about us'} span={'know about us'} />

      <div className='box-container'>

        <div className='video-container'>
          <ReactPlayer 
          url={images.about} 
          playing={true}
          loop={true}
          muted={true}
          width='100%'
          height='50rem'/> 
        </div>

        <div class="content">
          <h2>Energize your life with solar.</h2>
          <h5>Solar energy helps to protect your environment and Protect the Earth from Pollution.
            So say no to air pollution and water pollution.</h5>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Eum voluptatibus dolores aspernatur animi perferendis iste! 
              Culpa aut enim debitis optio illum ipsum quis perferendis.</p>
          <ul className='features'>
            <li><TiTick className='icon' />clean energy</li>
            <li><TiTick className='icon' />low installation cost</li>
            <li><TiTick className='icon' />easy installation</li>
            <li><TiTick className='icon' />renewable resources</li>
          </ul>
          <Button link={'contact'} title={'contact us'} />
        </div>
 
      </div> 

    </section>
  )
}

export default About;